// Angular
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { UserManagmentService } from 'src/app/core/auth/_services/user-managment.service';
import { Notifications } from 'src/app/shared/models/notifications';

@Component({
  selector: 'kt-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['notification.component.scss']
})
export class NotificationComponent {

  // Show dot on top of the icon
  @Input() dot: string;

  // Show pulse on icon
  @Input() pulse: boolean;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() type: 'brand' | 'success' = 'success';

  /**
   * Component constructor
   *
   * @param sanitizer: DomSanitizer
   */
  currentUserId:number;
   notificationCount :number = 0;
   notifications : Notifications[] = [];
  constructor(private sanitizer: DomSanitizer , 
    private userManagmentService : UserManagmentService,
    private token: TokenStorageService,
    private router: Router,
    private changeDetector : ChangeDetectorRef ,
    ) {
  }
  ngOnInit(): void {
    this.currentUserId = this.token.getUser().id;
    this.getUserNotificationCount();
    this.getUserNotifications();
  }
  getUserNotificationCount(){
    this.userManagmentService.GetUserNotificationCount(this.currentUserId).subscribe(res=>{
     this.notificationCount = res;
  });
}
  getUserNotifications(){
    this.userManagmentService.GetUserNotifications(this.currentUserId).subscribe(res=>{
      this.notifications = res;
     // console.log(this.notifications);
   });
  }

  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }

    return 'url(' + this.bgImage + ')';
  }
  notificationSeen(notificationId)
  {
    this.userManagmentService.NotificationSeen(notificationId).subscribe(res=>{
     this.removeNotification(notificationId);
     this.getUserNotificationCount();
     this.reloadComponent();
     this.changeDetector.detectChanges();
     this.changeDetector.reattach();
   });
  }
  removeNotification(notificationId){
    this.notifications.forEach( (item, index) => {
      if(item.id === notificationId) this.notifications.splice(index,1);
    });
 }
 reloadComponent() {
  let currentUrl = this.router.url;
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';
      this.router.navigate([currentUrl]);
  }
}
