<div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block;">
		<div class="text-center mb-10 mb-lg-20">
			<h3 class="font-size-h1">Sign Up</h3>
			<p class="text-muted font-weight-bold">Enter your details to create your account</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="registerForm" autocomplete="off" novalidate="novalidate">
			<div class="form-group">
				<mat-form-field>
					<mat-label>Name</mat-label>
					<input matInput type="text" placeholder="Name" formControlName="Name" />
					<mat-error *ngIf="isControlHasError('Name','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('Name','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('Name','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.EMAIL' | translate }}</mat-label>
					<input matInput type="Email" placeholder="{{ 'AUTH.INPUT.EMAIL' | translate }}"
						formControlName="Email" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('Email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('Email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('Email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('Email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>UserName</mat-label>
					<input matInput type="text" placeholder="UserName" formControlName="UserName" />
					<mat-error *ngIf="isControlHasError('UserName','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('UserName','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('UserName','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}"
						formControlName="password" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('password','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('password','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}</mat-label>
					<input matInput type="password" placeholder="{{ 'AUTH.INPUT.CONFIRM_PASSWORD' | translate }}"
						formControlName="confirmPassword" autocomplete="off" />
					<mat-error *ngIf="isControlHasError('confirmPassword','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('confirmPassword','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
					</mat-error>
					<mat-error
						*ngIf="registerForm.get('confirmPassword').errors && registerForm.get('confirmPassword').errors.ConfirmPassword">
						<strong>Passsword and ConfirmPassword didn't match.</strong>
					</mat-error>
				</mat-form-field>
			</div>
			<div class="form-group">
				<mat-checkbox name="agree" formControlName="agree">
					I agree the <a href="javascript:;">terms & conditions</a>
				</mat-checkbox>
			</div>

			<!--begin::Action-->
			<div class="form-group d-flex flex-wrap flex-center">
				<button (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">{{ 'AUTH.GENERAL.SUBMIT_BUTTON' | translate }}</button>
				<a href="/auth/login" id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">Cancel</a>
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>