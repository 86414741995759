// Angular
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { User } from 'src/app/core/auth';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'kt-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, AfterViewInit {
  // Public properties
UserName:string;
ImagePath:string;
user:User;
  // Set icon class name
  @Input() icon = 'flaticon2-shopping-cart-1';
  @Input() iconType: '' | 'brand';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;

  // Set bg image path
  @Input() bgImage: string;

  /**
   * Component constructor
   */
  constructor(private tokenStorageService:TokenStorageService,private sanitizer: DomSanitizer,private router: Router) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * After view init
   */
  ngAfterViewInit(): void {
  }

  /**
   * On init
   */
  ngOnInit(): void {
    this.user = this.tokenStorageService.getUser();
    if(this.user === null)
    {
      this.router.navigate(['auth/login']);
     }
      else{
        this.UserName = this.tokenStorageService.getUser().userName;
        // tslint:disable-next-line:prefer-const
        this.ImagePath =environment.staticFilesUrl+ this.tokenStorageService.getUser().imagePath;
      }
  }


 sanitizeImageUrl(imageUrl: string): SafeUrl {
      return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  logout(){
    this.tokenStorageService.signOut();
    // window.location.reload();
    this.router.navigate(['auth/login']);
  }
}
