<div class="card">
    <div *ngIf="status == true">
      <div  class="card-header">
        <h1>Accept Negotiation Draft</h1>
      </div>
    </div>
    <div *ngIf="status == false">
      <div  class="card-header">
        <h1>Reject Negotiation Draft</h1>
      </div>
    </div>
    <div class="card-body">
      <form class="form"  name="form" 
      (ngSubmit)="f.form.valid"
      #f="ngForm"
      novalidate>
        <div class="form-group">
          <div class="row">
            <div class="form-group col-12">
              <label for="comment">Comment</label>
              <textarea class="form-control" rows="3" name="comment"  #comment="ngModel" [(ngModel)]="form.comment"></textarea>
              <!-- <div
              class="text-muted" style="width:100%"
              *ngIf="f.submitted && !form.comment">
              Comment is required!
              </div> -->
              </div>
              <div *ngIf="status == false">
                <div  class="form-group col-12">
                  <label for="file">Attach File</label>
                  <input type="file" class="form-control-file"
                  name="file"
                  
                  placeholder="upload document"
                  (change)="onFileSelect($event)"
                  #file="ngModel"
                  [(ngModel)]="form.file"
                    >
                  <!-- <div
                  class="text-muted" style="width:100%"
                  *ngIf="!form.file">
                  Attached File is required!
                  </div> -->
                </div>
              </div>
          </div>

        </div>
<div>
  <button (click)="confirm()" class="btn btn-success btns">Confirm</button>
  <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
      </form>

    </div>

</div>

