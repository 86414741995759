import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Draft } from 'src/app/shared/models/draft';
import { Process } from 'src/app/shared/models/process';
import { User } from '../_models/user.model';
import jwt_decode from "jwt-decode";
import { MsalService } from '@azure/msal-angular';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const DRAFT_KEY ='auth-draft';
const CONNECTION_ID = 'connection-id';
const NOTIFICATION_COUNT = 'notification-count';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor(private authService: MsalService) { }

  signOut() {
    window.localStorage.clear();

    // if(this.authService.instance.getActiveAccount() != null)
    this.authService.logoutRedirect()
    
  }

  public saveToken(token: string) {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user) {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(){
    return JSON.parse(localStorage.getItem(USER_KEY));
  }
  public saveUserNotificationCount(count) {
    window.localStorage.removeItem(NOTIFICATION_COUNT);
    window.localStorage.setItem(NOTIFICATION_COUNT, JSON.stringify(count));
  }

  public getUserNotificationCount(){
    return JSON.parse(localStorage.getItem(NOTIFICATION_COUNT));
  }

  public getCurrentUser() {
    return JSON.parse(localStorage.getItem(USER_KEY));
  }
  public saveCurrentDraft(draft)
  {
    window.localStorage.removeItem(DRAFT_KEY);
    window.localStorage.setItem(DRAFT_KEY, JSON.stringify(draft));
    
  }
  // public getCurrentDraft() {
  //   let currentProcess = JSON.parse(localStorage.getItem(USER_KEY)).activeProcess;
  //   currentProcess = null;
  //   return localStorage.setItem(USER_KEY , JSON.stringify(currentProcess));
  // }
  public deleteCurrentDraft()
  {
    let currentDraft = JSON.parse(localStorage.getItem(USER_KEY));
    let activeProcess = currentDraft['activeProcess'];
    activeProcess['id'] = 0;
    activeProcess['processTemplatesRequstedId'] = null;
    activeProcess['templateRequestedId'] = null;
     activeProcess['currentStep'] = null;
    return localStorage.setItem(USER_KEY , JSON.stringify(currentDraft));
  }
  public updateDraftStep(currentStep , processId , processesTemplatesRequestedRelId , templateRequestedId)
  {
    let draft = JSON.parse(localStorage.getItem(USER_KEY));
    let activeProcess = draft['activeProcess'];
    if(activeProcess != null){
      activeProcess['id'] = processId;
      activeProcess['processTemplatesRequstedId'] = processesTemplatesRequestedRelId;
      activeProcess['templateRequestedId'] = templateRequestedId;
    activeProcess['currentStep'] = currentStep;
    window.localStorage.setItem(USER_KEY, JSON.stringify(draft));
    }
  }
  public setTheCurrentDraftToContinue(processId , processTempReqRelId , tempReqId , tempDataId ,currentStep )
  {
    let draft = JSON.parse(localStorage.getItem(USER_KEY));
    let activeProcess = draft['activeProcess'];
      activeProcess['id'] = processId;
      activeProcess['processTemplatesRequstedId'] = processTempReqRelId;
      activeProcess['templateRequestedId'] = tempReqId;
      activeProcess['templateDataId'] = tempDataId;
    activeProcess['currentStep'] = currentStep;
    window.localStorage.setItem(USER_KEY, JSON.stringify(draft));
  }
  public isLoggedIn()
  {
    let token = localStorage.getItem(TOKEN_KEY);
    let checkToken = this.getTOkenExpireTime(token);
   // console.log(checkToken);
    if(token == null || token == undefined ||checkToken==false )
    {
      return false;
    }
    else{
      return true;
    }
  }


  getTOkenExpireTime(token:string) {

    // let token = JSON.stringify(localStorage.getItem('token'));
  
    var decoded = jwt_decode(token);  
    //console.log( decoded["exp"]);
    if ( token == 'null' || token == null || decoded["exp"] < Date.now() / 1000) {
   
       localStorage.clear();
          return false
    }
    else
     return true;
    
  }
  public saveUserConnectionId(connectionId) {
    window.localStorage.removeItem(CONNECTION_ID);
    window.localStorage.setItem(CONNECTION_ID, JSON.stringify(connectionId));
  }
  public getUserConnectionId() {
    return JSON.parse(localStorage.getItem(CONNECTION_ID));
  }
}
