<div class="card">
    <div class="card-header">
      <h1>Accept Business Case</h1>
    </div>
    <div class="card-body">
        <div>Are You Sure You Want To accept?</div>
<div md-dialog-actions class="dialogAction">
  <button class="btn btn-success btns" (click)="confirm()">Confirm</button>
  <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
</div>
</div>

</div>

