import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StagingBaseURL } from 'src/app/core/auth/_services/global/global-config';
import { Draft } from '../models/draft';
import {TokenStorageService} from'../../../app/core/auth/_services/token-storage.service'
import { ExternalExpr } from '@angular/compiler';
import { ExternalFiles } from '../models/external-file';
import { Clause } from '../models/clause';
import { TemplateDetails } from '../models/template-details';
import { I } from '@angular/cdk/keycodes';
import { environment } from 'src/environments/environment';
import { AcceptRejectBusinessCase } from '../models/accept-reject-business-case';
import { AcceptRejectNegotiation } from '../models/accept-reject-negotiation';
import { PendingDraft } from '../models/pending-draft';
import { ReportKeepTrackProcessModel } from '../models/reportKeepTrackProcessModel';
import { AcceptRejectDraft } from '../models/accept-reject-draft';
import { map } from 'rxjs/operators';
// tslint:disable-next-line:whitespace
const DraftURL = environment.contratoApiUrl+'Draft/PostProcess';
const ProductDetailsURL = environment.contratoApiUrl+'Draft/PostProductDetails';
const PaymentDetailsDetailsURL = environment.contratoApiUrl+'Draft/PostPaymentDetails';
const RevenueShareDetailsDetailsURL = environment.contratoApiUrl+'Draft/PostRevenueShareDetails';
const AccountManagerTargetDetailsDetailsURL = environment.contratoApiUrl+'Draft/PostAccountManagerTargetDetails';
const ExternalFilesURL = environment.contratoApiUrl+'Draft/PostExternalFiles';
const ProcessClausesURL = environment.contratoApiUrl+'Draft/PostClauses';
const CheckUserDraftRequestURL = environment.contratoApiUrl+'Draft/CheckUserDraftRequest';
const GetTemplateDetailsURL = environment.contratoApiUrl+'Draft/GetTemplateDetails';
const GetPartyDetailsURL = environment.contratoApiUrl+'Draft/GetPartyDetails';
const GetEssentialDataDetailsURL = environment.contratoApiUrl+'Draft/GetEsssentialDataDetails';
const GetDraftTagsDataDetailsURL = environment.contratoApiUrl+'Draft/GetDraftTags';
const GetProductTypesURL = environment.contratoApiUrl+'Draft/GetProductDetails';
const GetPaymentDetailsURL = environment.contratoApiUrl+'Draft/GetPaymnetDetails';
const GetRevenueShareDetailsURL = environment.contratoApiUrl+'Draft/GetRevenueShareDetails';
const GetAccountMangerTargetDetailsURL = environment.contratoApiUrl+'Draft/GetAccountManagetTargetDetails';
const GetExternalFileDetailsURL = environment.contratoApiUrl+'Draft/GetExternalFileDetails';
const GetLegalFile = environment.contratoApiUrl+'Party/DownloadLegalFile';
const GetClausesDetailsURL = environment.contratoApiUrl+'Draft/GetClausesDetails';
const postDraftURL = environment.contratoApiUrl+'Draft/SaveDraftRequest';
const deleteDraft = environment.contratoApiUrl+'Draft/DeletePendingDraft';
const GetProcessDataURL = environment.contratoApiUrl+'Draft/GetProcessData';
const AcceptProcessURL =  environment.contratoApiUrl+'BusinessCaseStatus/AcceptProcess';
const RejectProcessURL = environment.contratoApiUrl+'BusinessCaseStatus/RejectProcess';
const AcceptNegotiationURL = environment.contratoApiUrl +'Negotiation/AcceptNegotiation';
const RejectNegotiationURL = environment.contratoApiUrl +'Negotiation/RejectNegotiation';
const GetProcessDetailsLogURL = environment.contratoApiUrl + 'Draft/GetProcessDetailsLog'
const FollowMyDraftsURL = environment.contratoApiUrl+'Reporting/FollowMyDrafts';
const GetUserPendingDraftsURL = environment.contratoApiUrl + 'Draft/GetUserPendingDrafts';
const GetCommercialPendingDrafts = environment.contratoApiUrl + 'Draft/GetCommercialPendingDrafts';
const PostDraftCommericalApprovalURL = environment.contratoApiUrl + 'Draft/PostDraftCommericalApproval';
const GetCCRequesterURL = environment.contratoApiUrl + 'Draft/GetCCRequester';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //application/json
};
@Injectable({
  providedIn: 'root'
})
export class DraftService {
  constructor(private http: HttpClient , private tokenStorageService :TokenStorageService) { }
    AddProcess(draft: Draft): Observable<any> {
    var userId = this.tokenStorageService.getUser().id;
    
    return this.http.post(DraftURL, {
       docSourceId: parseInt(draft.documentSourceId),
       party : draft.party,
       templateTypeId: draft.templateTypeId,
       languageId:draft.languageId,
       docType: draft.documentTypeId,
       reqUserId : userId,
       contractId : draft.contractId,
       startDate :draft.startDate,
       endDate :draft.endDate,
       documentTags : draft.tags,
       entityId : draft.entityId,
       processId: draft.processId,
       processTemplateRequestedId : draft.processesTemplatesRequestedRelId,
       templateRequestedId : draft.templateRequestedId,
       serviceDescription : draft.serviceDescription,
       remarks : draft.remarks,
       endNegotaitionPeriod : draft.endNegotaitionPeriod,
       gracingMonths: draft.gracingMonths,
       endingTermsId : draft.endingTermsId,
       thirdPartyId : draft.thirdPartyId,
       annexTypeId : draft.annexTypeId,
       specialityId :draft.specialityId,
       subSpecialityId :draft.subSpecialityId,
       serviceTypes  :draft.serviceTypes

    },httpOptions);
  }
  AddProductDetails(draft: Draft): Observable<any> {
  return this.http.post(ProductDetailsURL, {
    processTemplateRequestedId: draft.processesTemplatesRequestedRelId,
    productTypes :draft.productTypes,
    operators :draft.operators,
    territories : draft.territories,
    processId: draft.processId
  },httpOptions);
}
AddPaymentDetails(draft: Draft): Observable<any> {
  return this.http.post(PaymentDetailsDetailsURL, {
    amount: draft.paymentAmount,
    currencyFk :draft.currencyId,
    paymentTypeFk :draft.paymentTypeId,
    includeTaxs : draft.includeTax,
    PaymentTerms :draft.paymentTerms,
    processTemplateReqRelId : draft.processesTemplatesRequestedRelId,
    processId: draft.processId,
    revShare : draft.revenueShareValue,
    paymentConditions : draft.paymentCondition,
    templateRequestedId: draft.templateRequestedId,
    stepNo :draft.stepNo,
    thirdPartyObligation  : draft.thirdPartyObligation
   
  },httpOptions);
}


AddRevenueShareDetails(draft: Draft){
  var userId = this.tokenStorageService.getUser().id;
  return this.http.post(RevenueShareDetailsDetailsURL, {
    revShareLst: draft.RevenueShareProductChannel,
    processTemplateReqRelID : draft.processesTemplatesRequestedRelId,
    processId: draft.processId,
    processTemplateReqRelId : draft.processesTemplatesRequestedRelId,
    selectedRevenueShareType: draft.selectedRevenueShareType,
    fixedRevenueShare : draft.fixedRevenueShare,
    userId : userId
  },httpOptions);
}
AddAccountManagerTargetDetails(draft: Draft)
{
  return this.http.post(AccountManagerTargetDetailsDetailsURL, {
    ProcessTemplateReqAccountManagerLST: draft.AccountManagerTargets,
    ProcessTemplateReqRelId : draft.processesTemplatesRequestedRelId,
    processId: draft.processId,
  },httpOptions);
}
AddExternalFiles(externalFiles: ExternalFiles[]): Observable<any>
{
  const formData = new FormData();
  externalFiles.forEach(element => {
    if(element.processesDocRelId != undefined)
    {
    formData.append('oldDocs', ''+ element.processesDocRelId+'');
    // formData.append('partyFK', ''+externalFiles[0].partyFK+'');
    // formData.append('procTempReqId', ''+externalFiles[0].procTempReqId+'');
    // formData.append('processesId', ''+externalFiles[0].processesId+'');
    // formData.append('userId', ''+externalFiles[0].uploaderId+'');
    }

  });


//new documents
  for (let i = 0; i < externalFiles.length; i++) {
    if(externalFiles[i].document != undefined)
    {
      formData.append('document', <File>externalFiles[i].document);
      formData.append('docType', ''+externalFiles[i].documentTypeId+'');
      formData.append('docFk', ''+externalFiles[i].docFk+'');


    }

  }
  formData.append('partyFK', ''+externalFiles[0].partyFK+'');
  formData.append('procTempReqId', ''+externalFiles[0].procTempReqId+'');
  formData.append('processesId', ''+externalFiles[0].processesId+'');
  formData.append('userId', ''+externalFiles[0].uploaderId+'');
  formData.append('stepNo', ''+externalFiles[0].stepNo+'');

  // formData.append('oldDocs', ''+ alphas+'');
  return this.http.post(ExternalFilesURL, formData);
}
async downloadFile(url: string): Promise<Blob> {
  const response = await fetch(url);
  if (!response.ok) {
      throw new Error('Network response was not ok');
  }    const blob = await response.blob();
  return blob;
}
 DownLoadExternalFiles(fileUrl: string) : Observable<Blob>
 {
  const url = `${GetLegalFile}?fileUrl=${fileUrl}`;
  return this.http.get(url, {
    responseType: 'blob' as 'json', // Specify that the response is of type Blob
  }).pipe(
    map((res: Blob) => {
      return res;
    })
  );
}
saveFile(blob: Blob, fileName: string): void {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}
AddProcessClauses(clauses: Clause[]): Observable<any>
{
  return this.http.post(ProcessClausesURL, clauses,httpOptions);
}
CheckUserDraftRequest(id: number): Observable<any> {
  return this.http.get(CheckUserDraftRequestURL+`?UserId=${id}`);
}
GetTemplateDetails(templateDataId : number , processId :number): Observable<TemplateDetails> {
  return this.http.get<TemplateDetails>(GetTemplateDetailsURL+`?templateDataId=${templateDataId}&processId=${processId}`);
}
GetPartyDetails(processId : number): Observable<any> {
  return this.http.get(GetPartyDetailsURL+`?processId=${processId}`);
}
GetEsssentialDataDetails(templateRequestedId): Observable<any>
{
  return this.http.get(GetEssentialDataDetailsURL+`?templateRequestedId=${templateRequestedId}`);
}
GetDraftTags(templateRequestedId) : Observable<any>{
  return this.http.get(GetDraftTagsDataDetailsURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
GetProductDetails(templateRequestedId) : Observable<any>{
  return this.http.get(GetProductTypesURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
GetPaymnetDetails(templateRequestedId) : Observable<any>
{
  return this.http.get(GetPaymentDetailsURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
GetRevenueShareDetails(templateRequestedId) : Observable<any>
{
  return this.http.get(GetRevenueShareDetailsURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
GetAccountManagetTargetDetails(templateRequestedId) : Observable<any>
{
  return this.http.get(GetAccountMangerTargetDetailsURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
GetExternalFileDetails(processId) : Observable<any>
{
  return this.http.get(GetExternalFileDetailsURL+`?processId=${processId}`);
}
GetClausesDetails(templateRequestedId) : Observable<any>{
  return this.http.get(GetClausesDetailsURL+`?processTemplateReqRelId=${templateRequestedId}`);
}
SaveDraftRequest(draft:Draft) : Observable<any>{
var userConnectionId = this.tokenStorageService.getUserConnectionId();
let amount = (draft.paymentAmount > 0)? true : false;
let userId = this.tokenStorageService.getUser().id;
let userMail = this.tokenStorageService.getUser().email;
return this.http.post(postDraftURL, {
  userId : userId,
  processId : draft.processId,
  processTemplateReqRelId : draft.processesTemplatesRequestedRelId,
  withAmount : amount,
  useremail :userMail,
  templateTypeName : draft.templateTypeName,
  partyName :draft.partyName,
  ccUserId :draft.ccUserId,
  ccUserEmail : draft.ccUserEmail,
  userConnectionId : userConnectionId,
  templateType : draft.templateTypeId
},httpOptions);
}
DeletePendingDraft(processId , tempReqRelId,templateRequestedId): Observable<any>{
  return this.http.post(deleteDraft+`?processId=${processId}&processTemplateReqRel=${tempReqRelId}&templateRequestedId=${templateRequestedId}` ,httpOptions);
}
getProcessData(processId): Observable<any>{
  return this.http.get(GetProcessDataURL+`?processId=${processId}`);
}
// AcceptProcess(processId , amount, processTempReqRelId): Observable<any>{
//   return this.http.post(AcceptProcessURL+`?processId=${processId}&amount=${amount}&processTempReqRelId=${processTempReqRelId}` , httpOptions);
// }
AcceptProcess(form : AcceptRejectBusinessCase): Observable<any>{
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  return this.http.post(AcceptProcessURL,{
    processId : form.processId,
    processsTempReqRelId : form.processTempReqRelId,
    userId:form.userId,
    amount :form.amount,
    useremail :form.userMail,
    templateTypeName : form.templateTypeName,
    partyName :form.partyName,
    userConnectionId : userConnectionId
  } , httpOptions);
}
RejectProcess(form : AcceptRejectBusinessCase): Observable<any>{
  return this.http.post(RejectProcessURL,{
    processId : form.processId,
    processsTempReqRelId : form.processTempReqRelId,
    comment: form.comment,
    userId:form.userId,
    useremail :form.userMail,
    templateTypeName : form.templateTypeName,
    partyName :form.partyName
  } , httpOptions);
}
AcceptNegotiation(model : AcceptRejectNegotiation): Observable<any>{
  var userId = this.tokenStorageService.getUser().id;
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  const formData = new FormData();
      formData.append('document', <File>model.file);
      formData.append('comment', model.comment);
      formData.append('procTempReqId', ''+model.processTemplateReqRelId+'');
      formData.append('userId', userId);
      formData.append('processId',''+ model.processId+'');
      formData.append('userMail',''+ model.userMail+'');
      formData.append('templateTypeName',''+ model.templateTypeName+'');
      formData.append('templateTypeId',''+ model.templateTypeId+'');
      formData.append('partyName',''+ model.partyName+'');
      formData.append('PaymentAmount',''+ model.PaymentAmount+'');
      formData.append('alignment',''+ model.alignment+'');
      formData.append('confirmWithCommercial',''+ model.confirmWithCommercial+'');
      formData.append('userConnectionId',''+ userConnectionId+'');


  return this.http.post(AcceptNegotiationURL, formData);
}
RejectNegotiation(model : AcceptRejectNegotiation): Observable<any>{
  var userId = this.tokenStorageService.getUser().id;
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  const formData = new FormData();
      formData.append('document', <File>model.file);
      formData.append('comment', model.comment);
      formData.append('procTempReqId', ''+model.processTemplateReqRelId+'');
      formData.append('userId', userId);
      formData.append('processId',''+ model.processId+'');
      formData.append('userMail',''+ model.userMail+'');
      formData.append('templateTypeName',''+ model.templateTypeName+'');
      formData.append('partyName',''+ model.partyName+'');
      formData.append('userConnectionId',''+ userConnectionId+'');
      formData.append('nextStep',''+ model.nextStep+'');
  return this.http.post(RejectNegotiationURL, formData);
}
GetUserPendingDrafts(id: number) {
  return this.http.get<PendingDraft[]>(GetUserPendingDraftsURL+`?UserId=${id}`);
}
GetAllProcessLog(processId) : Observable<any>
{
  return this.http.get(GetProcessDetailsLogURL+`?ProcessID=${processId}`);
}
GetCommercialPendingDrafts(id: number) {
  return this.http.get<PendingDraft[]>(GetCommercialPendingDrafts+`?UserId=${id}`);
}
PostDraftCommericalApproval(form : AcceptRejectDraft , status : boolean): Observable<any>{
  var userConnectionId = this.tokenStorageService.getUserConnectionId();
  const formData = new FormData();
      formData.append('Document', <File>form.file);
      formData.append('ProcessId', ''+form.processId+'');
      formData.append('ProcessTemplateReqRelId', ''+form.processTemplateReqRelId+'');
      formData.append('Comment', form.comment);
      formData.append('UserId',''+ form.userId+'');
      formData.append('Status',''+ status+'');
      formData.append('UserMail',''+ form.userMail+'');
      formData.append('PartyName',''+ form.partyName+'');
      formData.append('TemplateDataId',''+ form.templateDataId+'');
      formData.append('PaymentAmount',''+ form.PaymentAmount+'');
      formData.append('TemplateTypeName',''+ form.templateTypeName+'');
      formData.append('templateTypeId',''+ form.templateTypeId+'');
      formData.append('UserConnectionId',''+ userConnectionId+'');
  return this.http.post(PostDraftCommericalApprovalURL, formData);
  // return this.http.post(PostDraftCommericalApprovalURL,{
  //   processId : form.processId,
  //   processTemplateReqRelId : form.processTemplateReqRelId,
  //   comment: form.comment,
  //   userId:form.userId,
  //   status: status,
  //   userMail: form.userMail,
  //   partyName:form.partyName,
  //   templateDataId :form.templateDataId,
  //   templateTypeName :form.templateTypeName
  // } , httpOptions);
}

FollowMyDrafts(userId: number)
  {
     return this.http.get<ReportKeepTrackProcessModel[]>(FollowMyDraftsURL+`?userId=${userId}` ,httpOptions);
  }
  GetCCRequester(processId: number) {
    return this.http.get<number>(GetCCRequesterURL+`?processId=${processId}`);
  }
}
