// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TokenStorageService } from 'src/app/core/auth/_services/token-storage.service';
import { Router } from '@angular/router';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
 const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(private token: TokenStorageService ,private router: Router) { }
  // intercept request and add token
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let authReq = req;
    const token = this.token.getToken();
    if (token != null) {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
    }
    // return next.handle(authReq);
    return next.handle(authReq).pipe( tap(() => {},
    (err: any) => {
    if (err instanceof HttpErrorResponse) {
      if (err.status !== 401) {
       return;
      }
      this.router.navigateByUrl('auth/login');
    }
  }));
}
  }

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptService, multi: true }
];
