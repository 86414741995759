import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AcceptRejectNegotiation } from 'src/app/shared/models/accept-reject-negotiation';
import { ApprovalService } from 'src/app/shared/services/approval.service';
import { SharedService } from 'src/app/shared/services/shared.service';
 
export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'kt-replace-document',
  templateUrl: './replace-document.component.html',
  styleUrls: ['./replace-document.component.scss']
})
export class ReplaceDocumentComponent implements OnInit {

  constructor(
    // public dialogRef: MatDialogRef<ReplaceDocumentComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,

    public  dialogRef:  MatDialogRef<ReplaceDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public  data:  any,
    private toastr: ToastrService,
    public sharedService:SharedService,
    private router: Router,
    private approvalService:ApprovalService
  ) { }

  form: AcceptRejectNegotiation = new AcceptRejectNegotiation();
  fileExtention;
  fileUrl;
  ngOnInit(): void { 
      this.form.processTemplateReqRelId = this.data.processTemReqId;
      this.form.processId = this.data.processId;
      //this.form.userMail = this.tokenStorageService.getUser().email;
      this.form.partyName = this.data.partyName;
      this.form.templateTypeName = this.data.documentTypeName;
  }



  onFileSelect(event):void{
    if(event.target.files.length > 0){
      const file = event.target.files[0];
      
      if(file.type =="application/pdf" || file.type =="application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      {   
        this.form.file = file;
        
      }

        else
        {
          this.toastr.warning("You can only upload Pdf / Word document. 😟" , "Warning!");
          this.form.file =  null;
          return;
        }
       
    
    }
  
    }


    confirm(){

      if( this.form.file==  null)
      {
      this.toastr.error("Please choose a vaild word or pdf document. 😟" , "Error!");
      return;
      }

      this.sharedService.sendClickEvent();
        this.approvalService.ReplaceNegotiationDocument(this.form).subscribe(res =>{
        this.toastr.success("Document Uploaded successfully 😀" , "Success!");
        this.router.navigate(['Approval/SearchApprovals']);

      },error=>{
        this.toastr.error("Failed to upload document 😟" , "Error!");
       // this.router.navigate(['Approval/SearchApprovals']);
      });
      this.dialogRef.close();
     
    }

    CloseDialog()
  {
    this.dialogRef.close();
  }




  // onNoClick(): void {
  //   this.dialogRef.close();
  // }

   

}
