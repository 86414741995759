export class AcceptRejectDraft{
    templateReqRelId:number;
    comment:string;
    processTemplateReqRelId:number;
    templateTypeId:number;
    PaymentAmount:number;
    processId :number;
    userMail:string;
    userId:number;
    templateTypeName:string;
    status:string;
    partyName:string;
    templateRequestedId:number;
    templateDataId:number;
    file:any;
}