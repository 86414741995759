// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import { isLoggedIn } from '../_selectors/auth.selectors';
import { TokenStorageService } from '../_services/token-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private token: TokenStorageService , private router: Router ,private store: Store<AppState>) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : Observable<boolean>{
    // let user = this.token.getUser();
    //   if (user) {
    //     return true;
    //   }else{
    //     this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    //     return false;
    //   }
    return this.store
      .pipe(
        select(isLoggedIn),
        tap(loggedIn => {
          if (!loggedIn || !this.token.isLoggedIn()) {
           // this.router.navigateByUrl('/auth/login');
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
          }
        })
      );
  }
}
