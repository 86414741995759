<!--begin: My Cart -->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="dropdown dropdown-fluid">
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
      <ng-container *ngIf="useSVG">
        <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="icon"></span>
      </ng-container>
      <ng-container *ngIf="!useSVG">
        <i [ngClass]="icon"></i>
      </ng-container>
    </div>
	</div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-xl dropdown-menu-anim-up">
		<form>
			<!-- begin:: My Cart -->
			<div class="d-flex align-items-center py-10 px-8 bgi-size-cover bgi-no-repeat rounded-top"
				[ngStyle]="{'background-image': 'url('+bgImage+')'}">
				<!-- <span class="btn btn-md btn-icon bg-white-o-15 mr-4">
					<i class="flaticon-user-settings"></i>
				</span> -->
				<h6 class="text-white m-0 flex-grow-1 mr-3">
					{{UserName}}
				</h6>
				<img style="width:100px; height: 100px; border-radius: 50%;" [src]="sanitizeImageUrl(ImagePath)">
			</div>



			<div class="p-8">
				<!-- <div class="d-flex align-items-center justify-content-between mb-4">
					<span class="font-weight-bold text-muted font-size-sm mr-2">
						Total
					</span>
					<span class="font-weight-bolder text-dark-50 text-right">
						$1840.00
					</span>
				</div>
				<div class="d-flex align-items-center justify-content-between mb-7">
					<span class="font-weight-bold text-muted font-size-sm mr-2">
						Sub total
					</span>
					<span class="font-weight-bolder text-primary text-right">
						$5640.00
					</span>
				</div> -->
				<div class="text-right">
					<button type="button" class="btn btn-primary text-weight-bold" (click)="logout()">
						LogOut
					</button>
				</div>
			</div>
			<!-- end:: My Cart -->
		</form>
	</div>
  <!--end::Dropdown-->
</div>
<!--end: My Cart -->
