<!--begin::Notifications-->
<div ngbDropdown placement="bottom-right" autoClose="outside" class="dropdown dropdown-fluid">
  <!--begin::Toggle-->
  <div ngbDropdownToggle class="topbar-item">
    <ng-container *ngIf="useSVG">
      <div class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1 pulse pulse-primary">
        <span class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="icon"></span>
        <span class="pulse-ring"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="!useSVG">
      <i [ngClass]="icon"></i>
    </ng-container>
  </div>
  <!--end::Toggle-->

  <!--begin::Dropdown-->
  <div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg show">
    <form>
      <!--begin::Header-->
      <div class="d-flex flex-column pt-10 bp-10 bgi-size-cover bgi-no-repeat rounded-top"
           [ngStyle]="{'background-image': backGroundStyle() }">
        <!--begin::Title-->
        <h4 class="d-flex flex-center rounded-top">
          <span class="text-white">User Notifications</span>
          <span class="btn btn-text btn-success btn-sm font-weight-bold btn-font-md ml-2">{{this.notificationCount}} new</span>
        </h4>
        <!--end::Title-->
        <!--begin::Tabs-->
        <ul ktTabClickEvent
            class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-2 px-6"
            role="tablist">
          <li class="nav-item">
            <a (click)="tab.select('tab-id-1')" class="nav-link active show" data-toggle="tab"
               href="javascript:;" role="tab" aria-selected="true">Alerts</a>
          </li>
          <!-- <li class="nav-item">
            <a (click)="tab.select('tab-id-2')" class="nav-link" data-toggle="tab" href="javascript:;"
              role="tab" aria-selected="false">Events</a>
          </li>
          <li class="nav-item">
            <a (click)="tab.select('tab-id-3')" class="nav-link" data-toggle="tab" href="javascript:;"
              role="tab" aria-selected="false">Logs</a>
          </li> -->
        </ul>
        <!--end::Tabs-->
      </div>
      <!--end::Header-->

      <ngb-tabset #tab="ngbTabset" class="tab-content">
        <ngb-tab id="tab-id-1" class="tab-pane">
          <ng-template ngbTabContent>
            <div class="p-8">
              <div class="pr-7 mr-n7 scroll" [perfectScrollbar]="{wheelPropagation: false}"
                   [ngStyle]="{'max-height': '300px', 'position': 'relative'}">
                <!--begin::Nav-->
                <div class="d-flex align-items-center mb-6" *ngFor="let item of notifications">
                  <div class="symbol symbol-40 symbol-light-primary mr-5">
										<span class="symbol-label">
											<span [inlineSVG]="'./assets/media/svg/icons/Home/Library.svg'"
                            class="svg-icon svg-icon-lg svg-icon-primary"></span>
										</span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a [href]="item.notificationURL" (click)="notificationSeen(item.id)" class="text-dark text-hover-primary mb-1 font-size-lg">
                      {{item.message}}
                    </a>
                    <span class="text-muted">
											<!-- PHP, SQLite, Artisan CLIмм -->
										</span>
                  </div>
                </div>
                <!-- <div class="d-flex align-items-center mb-6">
                  <div class="symbol symbol-40 symbol-light-warning mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/Communication/Write.svg'"
                        class="svg-icon svg-icon-lg svg-icon-warning"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark-75 text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-6">
                  <div class="symbol symbol-40 symbol-light-success mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/Communication/Group-chat.svg'"
                        class="svg-icon svg-icon-lg svg-icon-success"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-6">
                  <div class="symbol symbol-40 symbol-light-danger mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'"
                        class="svg-icon svg-icon-lg svg-icon-danger"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-40 symbol-light-info mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/General/Attachment2.svg'"
                        class="svg-icon svg-icon-lg svg-icon-info"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-40 symbol-light-info mr-5">
                    <span class="symbol-label">
                      <span
                        [inlineSVG]="'./assets/media/svg/icons/Communication/Mail-notification.svg'"
                        class="svg-icon svg-icon-lg svg-icon-info"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-2">
                  <div class="symbol symbol-40 symbol-light-info mr-5">
                    <span class="symbol-label">
                      <span [inlineSVG]="'./assets/media/svg/icons/Design/Bucket.svg'"
                        class="svg-icon svg-icon-lg svg-icon-info"></span>
                    </span>
                  </div>
                  <div class="d-flex flex-column font-weight-bold">
                    <a href="#" class="text-dark text-hover-primary mb-1 font-size-lg">
                      Briviba SaaS
                    </a>
                    <span class="text-muted">
                      PHP, SQLite, Artisan CLIмм
                    </span>
                  </div>
                </div> -->
                <!--end::Nav-->
              </div>
            </div>
          </ng-template>
        </ngb-tab>

      </ngb-tabset>
    </form>
  </div>
  <!--end::Dropdown-->
</div>
<!--end::Notifications-->
