<div class="card">
    <div class="card-header">
      <h1>Reject Business Case</h1>
    </div>
    <div class="card-body">
      <form class="form"  name="form" 
      (ngSubmit)="f.form.valid && confirm()"
      #f="ngForm"
      novalidate>
        <h4>Are You Sure You Want To reject?</h4>
        <div class="row">
          <div class="form-group col-12">
            <label for="exampleFormControlTextarea1">Comment</label>
            <textarea class="form-control" rows="3" required name="comment" #comment ="ngModel" [(ngModel)]="form.comment"></textarea>
            <div
            class="text-muted" style="width:100%"
            *ngIf="f.submitted && !form.comment">
            Comment is required!
            </div>
            </div>
        </div>
     <div md-dialog-actions class="dialogAction">
  <button type ="submit"  class="btn btn-success btns">Confirm</button>

 <button class="btn btn-danger btns btnsSide" (click)="dialogRef.close(false)">Cancel</button>
     </div>
      </form>
</div>


</div>

